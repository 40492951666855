<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div id="contents_wrap">
      <div class="contents">
        <div class="tab_container">
          <ul class="mypTabs" style="margin-top: 30px; margin-left:70px;">
            <li><router-link to="/info/user">내정보</router-link></li>
            <li><router-link to="/info/bet">베팅내역</router-link></li>
            <li><router-link to="/info/free_bet">프리베팅내역</router-link></li>
            <li><router-link to="/bbs/c/l">1:1 문의</router-link></li>
            <li><router-link to="/bbs/msg/l">쪽지함</router-link></li>
            <li><router-link class="on" to="/info/recommender">추천인</router-link></li>
          </ul>
          <div class="nbox size">

            <!--      <ul class="mypTabs" style="margin-top: 30px; margin-left:70px;">-->
            <!--        <li><router-link class="on" to="/info/user">내정보</router-link></li>-->
            <!--        <li><router-link to="/info/bet">베팅내역</router-link></li>-->
            <!--        <li><router-link to="/bbs/c/l">1:1 문의</router-link></li>-->
            <!--        <li><router-link to="/bbs/msg/l">쪽지함</router-link></li>-->
            <!--        <li><router-link to="/info/recommender">추천인</router-link></li>-->
            <!--      </ul>-->

            <p class="mypSubj"><a>추천인 리스트</a></p>
            <div class="tableWrap" style="display: block;">
              <table class="titleTable01 mt10">
                <colgroup>
                  <col width="224" />
                  <col width="548" />
                  <col width="*" />
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>가입일</th>
                </tr>
                <tr v-for="(row, index) in lists" :key="'childList' + index">
                  <td>{{ row.members_id }}</td>
                  <td>{{ row.members_nickname }}</td>
                  <td>{{ row.members_regdatetime | formatDate('YYYY-MM-DD') }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <a href="#" class="btn-prev"></a>
      </div>
      <h2>추천인</h2>
    </div>
    <div class="menu_2_2">
      <div style="width:100%">
        <div><router-link to="/info/user">내정보</router-link></div>
      </div>

      <div style="width:100%">
        <div ><router-link to="/bbs/c/l">1:1문의</router-link></div>
        <div><router-link to="/bbs/msg/l">쪽지함</router-link></div>
      </div>
      <div style="width:100%">
        <div class="active"><router-link to="/info/recommender">추천인</router-link></div>
        <div><router-link to="/info/free_bet">프리베팅내역</router-link></div>
      </div>
    </div>

    <div class="nbox size">


      <p class="mypSubj"><a href="#">추천인 내역</a></p>
      <div class="tableWrap" style="display: block;">
        <table class="titleTable01 mt10">
          <tr>
            <th>아이디</th>
            <th>닉네임</th>
            <th>가입일</th>
          </tr>
          <tr v-for="(row, index) in lists" :key="'childList' + index">
            <td>{{ row.members_id }}</td>
            <td>{{ row.members_nickname }}</td>
            <td>{{ row.members_regdatetime | formatDate('YYYY-MM-DD') }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Deposit",
  components: {
    // SideBox
  },
  data: function(){
    return {
      lists: []
    }
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData()
  },
  methods: {
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    reqData: function(){
      return this.$store.dispatch('GET_RECOMMENDER', { payload: this.payload } )
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}


/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

/*fade*/
.tab_content.fading{
  opacity: 1;
  animation: fading 0.4s linear;
}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}

.fading {
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 705ms;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-duration: 705ms;
}

@keyframes fading {
  from { opacity: 0 }
  to { opacity: 1 }

}
.dt-txt{
  /*overflow-y: scroll!important;*/
  height: 100px!important;
  background: #202122!important;
  /*padding: 25px 10px 25px 10px!important;*/
  padding:0px!important;
  /*text-align: left!important;*/
  /*transition: all 0.1s ease;*/
}
div.slider {
  display: none;
  text-align: left!important;
  /*padding: 25px 10px 25px 10px!important;*/
  padding-left:10px!important;
}
#qnaDt tbody tr{
  cursor: pointer;
  transition: all .2s ease-in-out;
}
#qnaDt tbody tr td.dt-txt {
  text-align: left!important;
  padding-left: 10px!important;
}
table#qnaDt tbody tr:hover {
  background: #293133!important;
}

table.dataTable thead th{
  color: #c1c3c4;
  background: #2e3334;
  height: 40px;
  text-align: center;
  border-right: solid 1px #1c2021;
  border-bottom: none!important;
  font-size: 12px!important;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif!important;
  color: #c1c3c4!important;
}
table.dataTable thead th:first-child{

  border-left: solid 1px #1c2021!important;

}
table.dataTable tbody tr {
  background-color: transparent!important;
  background: #262a2b!important;
}
table.dataTable tbody td {
  padding: 0px!important;
  text-align: center!important;
  border-left: solid 1px #1c2021!important;
  height: 35px!important;
  border-right: solid 1px #1c2021!important;
  font-size: 12px!important;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif!important;
}
.dataTables_scrollBody{
  border-top: 1px solid #000000!important;
}

.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232;padding:10px 0 10px 0;display: block;cursor: pointer; width:100%; text-indent:10px}
.smk_accordion .acc_section .acc_head h3 {line-height: 1;margin: 5px 0; font-size:14px; font-weight:700}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727;}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:60px; background:#191a1b;}
.acc_content_in_2 {padding:40px; background:#191a1b; }
.acc_btn_wrap {width:100%;text-align:center; margin:20px 0 0 0 }

.btn3ci {
  width: 168px;
  height: 42px;
  margin-right: 2px;
  background: #da2c36;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: -1px;
  cursor: pointer;
}

.btn3cbi {
  width: 168px;
  height: 42px;
  background: #2e3334;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: -1px;
  cursor: pointer;
}

/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#da2c36;
  font-weight: bold;
}

.acc_content_in_2 {padding:40px; background:#191a1b; }
.inquiry_wrap_1 {
  width: 100%;
  float: left;
  height: 30%;
  max-height: 350px!important;
}

#con-com {
  padding: 0px 15px 0px 15px;
}

/* 1:1문의 --팝업과 공통 */
.inquiry_wrap {width:100%; float:left; height: 100%;}
.inquiry {width:100%; float:left;padding:15px 0 15px 0}
.inquiry_user {width:100%;float:right; margin:0 0 0 8px;  width:auto; padding:20px; max-width:600px; background:#da2c36; border-radius:3px; position:relative;}
.user_date {position:absolute; right:-140px; bottom:10px}
.user_tag {position:absolute; top:5px; left:-8px}
.inquiry_admin {width:100%; float:left; margin:0 0 0 8px;  width:auto; padding:20px; max-width:600px; background:#30363c; border-radius:3px; position:relative;}
.inquiry_text {color:#ffffff;line-height:20px}
.admin_tag {position:absolute; top:5px; right:-7px}
.admin_date {position:absolute; right:-130px; bottom:10px}
.inquiry_select {width:100%; height:38px; background:#191a1b;  border:solid 1px #2e3032 ;padding:0 0 0 10px;}
.inquiry_select_wrap {width:100%; float:left; margin:30px 0 20px 0}
.inquiry_input {width:100%; height:38px; background:#191a1b; border:solid 1px #2e3032; padding:0 0 0 10px ;color:#c1c3c4  }

.inquiry_input {
  width: 100%;
  height: 38px;
  background: #191a1b;
  border: solid 1px #2e3032;
  padding: 0 0 0 10px;
  color: #c1c3c4;
}

button.btn5 {
  border-width: 0px!important;
  border-radius: 5px!important;
  cursor: pointer;
}
</style>
