<template>
  <section v-if="!isMobile">
    <div id="contents_wrap">
      <div class="contents" style="margin-bottom: -600px;">
        <div class="tab_container">
          <ul class="mypTabs" style="margin-top: 30px; margin-left:70px;">
            <li><router-link  to="/info/user">내정보</router-link></li>
            <li><router-link class="on" to="/info/bet">베팅내역</router-link></li>
            <li><router-link to="/info/free_bet">프리베팅내역</router-link></li>
            <li><router-link to="/bbs/c/l">1:1 문의</router-link></li>
            <li><router-link to="/bbs/msg/l">쪽지함</router-link></li>
            <li><router-link to="/info/recommender">추천인</router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrap" style="min-height: 800px; margin-top:100px;">
      <div class="bet_wrap">
        <div class="example1">
          <div class="panel panel-primary">
            <div
              v-if="!isLoading"
              style="text-align: center; padding-top: 20%; padding-bottom: 20%"
            >
              <Loading></Loading>
            </div>
            <form name="cbFormAll1" class="ng-pristine ng-valid" v-else>
              <div id="tableAll1_wrapper" class="dataTables_wrapper no-footer">
                <table
                  id="tableAll1"
                  cellspacing="0"
                  cellpadding="0"
                  width="100%"
                  class="dataTable no-footer"
                  role="grid"
                  style="width: 100%"
                >
                  <thead>
                    <tr role="row">
                      <th class="sorting" style="width: 60px">
                        <input type="checkbox" class="alltab_chckall" />
                      </th>
                      <th class="sorting" style="width: 220px">베팅 NO</th>
                      <th
                        class="sorting_desc"
                        style="width: 120px"
                        aria-sort="descending"
                      >
                        베팅 일시
                      </th>
                      <th class="sorting_asc" style="width: 130px">
                        베팅 종목
                      </th>
                      <th class="sorting" style="width: 130px">베팅 결과</th>
                      <th class="sorting" style="width: 168px">배팅 금액</th>
                      <th class="sorting" style="width: 143px">배당율</th>
                      <th class="sorting" style="width: 144px">
                        예상 적중 금액
                      </th>
                      <th class="sorting" style="width: 71px">폴더</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(row, index) in lists">
                      <tr
                        id="tableall1_2802777"
                        role="row"
                        class="odd"
                        :key="'bettingTitle' + index"
                      >
                        <td>
                          <input
                            type="checkbox"
                            name="cb"
                            class="dTable1_chk"
                            :true-value="
                              selectedBet.includes(row[0].betting_betcode)
                            "
                            :false-value="
                              !selectedBet.includes(row[0].betting_betcode)
                            "
                            @change="toggleCheckBox(row[0])"
                          />
                        </td>
                        <td @click="toggle(index)">
                          {{ row[0].betting_betcode }} <br>
                          {{ row[0].betting_type }}
                        </td>
                        <td @click="toggle(index)" class="sorting_1">
                          {{
                            row[0].betting_regdatetime
                              | formatDate("YYYY-MM-DD HH:mm:ss")
                          }}
                        </td>
                        <td @click="toggle(index)">
                          {{
                            row[0].betting_game_sports_name_kr ||
                            row[0].betting_game_sports_name_en
                          }}
                          {{ row.length > 1 ? "외" : "" }}
                        </td>
                        <td
                          @click="toggle(index)"
                          v-if="row[0].betting_status.indexOf('취소') < 0"
                        >
                          <span
                            :class="{
                              'text-yellow':
                                row[0].betting_total_result === 'exception',
                              'text-red':
                                row[0].betting_total_result === 'miss',
                              'text-green':
                                row[0].betting_total_result === 'hit',
                              'text-gray':
                                row[0].betting_total_result === 'wait',
                            }"
                            >
                            <template v-if="row[0].betting_total_result === 'hit'">
                              {{ row[0].betting_payed === 'y' ? '당첨' : '지급대기' }}
                            </template>
                            <template v-else>
                              {{row[0].betting_total_result | bettingStatus }}
                            </template>
                          </span
                          >
                        </td>
                        <td v-else>취소</td>
                        <td @click="toggle(index)">
                          <span
                            :class="{
                              'text-yellow':
                                row[0].betting_total_result === 'exception',
                              'text-red':
                                row[0].betting_total_result === 'miss',
                              'text-green':
                                row[0].betting_total_result === 'hit',
                              'text-gray':
                                row[0].betting_total_result === 'wait',
                            }"
                            >{{ row[0].betting_bet_amount | makeComma }}</span
                          >
                        </td>
                        <td @click="toggle(index)">
                          <span
                            :class="{
                              'text-yellow':
                                row[0].betting_total_result === 'exception',
                              'text-red':
                                row[0].betting_total_result === 'miss',
                              'text-green':
                                row[0].betting_total_result === 'hit',
                              'text-gray':
                                row[0].betting_total_result === 'wait',
                            }"
                            >{{ row[0].betting_total_odds }}
                          </span>
                        </td>
                        <td @click="toggle(index)">
                          <span
                            :class="{
                              'text-yellow':
                                row[0].betting_total_result === 'exception',
                              'text-red':
                                row[0].betting_total_result === 'miss',
                              'text-green':
                                row[0].betting_total_result === 'hit',
                              'text-gray':
                                row[0].betting_total_result === 'wait',
                            }"
                            >{{
                              row[0].betting_expected_prize | makeComma
                            }}</span>
                        </td>
                        <td @click="toggle(index)">
                          {{ row.length > 1 ? `${row.length}폴더` : "단폴더" }}
                        </td>
                      </tr>
                      <transition name="fade" :key="'bettingDetail' + index">
                        <tr v-if="opened.includes(index)">
                          <td colspan="9">
                            <div class="sub_table">
                              <table
                                id="sub_tableall1_2802725"
                                class="panel-body bet_dd_p"
                                cellpadding="0"
                                cellspacing="0"
                                :class="{
                                  acc_list_table_in_01:
                                    row[0].betting_total_result === 'exception',
                                  acc_list_table_in_02:
                                    row[0].betting_total_result === 'miss',
                                  acc_list_table_in_04:
                                    row[0].betting_total_result === 'hit',
                                  acc_list_table_in_03:
                                    row[0].betting_total_result === 'wait',
                                }"
                              >
                                <tbody>
                                  <tr>
                                    <td class="acc_list_table_in_t" width="11%">
                                      경기일시
                                    </td>
                                    <td class="acc_list_table_in_t" width="8%">
                                      종목
                                    </td>
                                    <td class="acc_list_table_in_t" width="15%">
                                      리그
                                    </td>
                                    <td class="acc_list_table_in_t" width="18%">
                                      팀
                                    </td>
                                    <td class="acc_list_table_in_t" width="10%">
                                      타입
                                    </td>
                                    <td class="acc_list_table_in_t" width="14%">
                                      배팅
                                    </td>
                                    <td class="acc_list_table_in_t" width="6%">
                                      배당율
                                    </td>
                                    <td class="acc_list_table_in_t" width="6%">
                                      결과
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(row2, index2) in row"
                                    :key="'bets' + row.bets_id + index2"
                                  >
                                    <td class="bet_time">
                                      {{
                                        row2.betting_game_starttime
                                          | formatDate("YYYY-MM-DD HH:mm:ss")
                                      }}
                                    </td>
                                    <td class="bet_event">
                                      {{
                                        row2.betting_game_sports_name_kr ||
                                        row2.betting_game_leagues_name_en
                                      }}
                                    </td>
                                    <td class="bet_name">
                                      {{
                                        row2.betting_game_leagues_name_kr ||
                                        row2.betting_game_leagues_name_en
                                      }}
                                    </td>
                                    <td class="bet_name">
                                      {{
                                        row2.betting_game_home_name_kr ||
                                        row2.betting_game_home_name_en
                                      }}
                                      -
                                      {{
                                        row2.betting_game_away_name_kr ||
                                        row2.betting_game_away_name_en
                                      }}
                                    </td>
                                    <td class="bet_type">
                                      {{
                                        row2.betting_game_markets_name_kr ||
                                        row2.betting_game_markets_name_en
                                      }}
                                      <span v-if="row2.betting_round">
                                        ({{ row2.betting_round }})
                                      </span>
                                    </td>
                                    <td class="bet_type">
                                      {{ row2 | betsName2 }}
                                    </td>
                                    <td class="bet_type">
                                      {{
                                        row.length === 1
                                          ? (
                                              Number(row[0].betting_odds) +
                                              Number(
                                                row[0]
                                                  .betting_total_odds_penalty
                                              )
                                            ).toFixed(2)
                                          : row2.betting_odds
                                      }}
                                    </td>
                                    <td
                                      class="beting_in_btn"
                                      v-if="
                                        row2.betting_status.indexOf('취소') < 0
                                      "
                                    >
                                      <span
                                        :class="{
                                          'text-yellow':
                                            row2.betting_result === 'exception',
                                          'text-red':
                                            row2.betting_result === 'miss',
                                          'text-green':
                                            row2.betting_result === 'hit',
                                          'text-gray':
                                            row2.betting_result === 'wait',
                                        }"
                                      >
                                        {{
                                          row2.betting_result | bettingStatus
                                        }}</span
                                      >
                                    </td>
                                    <td v-else>취소</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                class="btnBox02"
                                style="margin-top: 10px; text-align: right"
                                v-if="cancelStatus"
                              >
                                <template
                                  v-if="
                                    bettingCancelLoading.indexOf(
                                      row[0].betting_betcode
                                    ) < 0
                                  "
                                >
                                  <a
                                    v-if="cancelStatus(row)"
                                    @click="
                                      cancelBetting(row[0].betting_betcode)
                                    "
                                    class="btn05"
                                    >베팅 취소</a
                                  >
                                </template>
                                <template v-else>
                                  <Loading></Loading>
                                </template>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </transition>
                    </template>
                  </tbody>
                </table>

                <Pagination
                  v-if="pagination.end"
                  :pagination="pagination"
                  :req-data="reqData"
                  :payload="payload"
                ></Pagination>
                <div class="btnBox02">
                  <a
                    @click="deleteAllBetting"
                    style="margin-right: 3px"
                    class="btn05"
                    >베팅내역 전체 삭제</a
                  >
                  <a @click="deleteSelectedBetting" class="btn05"
                    >선택 베팅내역 삭제</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>베팅내역</h2>
      <div class="right">
        <button class="m_delete_btn" @click="deleteAllBetting">전체삭제</button>
      </div>
    </div>
    <div class="menu_2_2">
      <div style="width:100%">
        <div><router-link to="/info/user">내정보</router-link></div>
        <div class="active"><router-link to="/info/bet">베팅내역</router-link></div>
      </div>

      <div style="width:100%">
        <div ><router-link to="/bbs/c/l">1:1문의</router-link></div>
        <div><router-link to="/bbs/msg/l">쪽지함</router-link></div>
      </div>
      <div style="width:100%">
        <div><router-link to="/info/recommender">추천인</router-link></div>
        <div><router-link to="/info/free_bet">프리베팅내역</router-link></div>
      </div>
    </div>

    <div class="wrap">

      <div class="tab_content" style="background-color: rgb(25, 26, 27); display: block;">

        <form name="form_search" action="./bet_lst" method="GET">
          <input type="hidden" name="tab" value="ALL">
          <input type="hidden" name="pages" value="1">

          <div class="my_search_wrap" style="margin:0 0 10px 0;">
            <select class="input_style01" name="game">
              <option value="SPORT">스포츠</option>
<!--              <option value="ASIAGAME">아시안게이밍</option>-->
<!--              <option value="EVOLUTION">에볼루션 &amp; NETENT, RED TIGER, STG</option>-->
<!--              <option value="ALEA_VS">ALEA 가상게임</option>-->
<!--              &lt;!&ndash;<option value="MRSLOTTY_VG">MrSlotty 가상게임</option>&ndash;&gt;-->
<!--              <option value="PROGMATIC">프로그매틱</option>-->
<!--              <option value="MG_SLOT">MG 슬롯</option>-->
<!--              <option value="MG_CASINO">MG 카지노</option>-->
            </select>
          </div>

<!--          <div class="my_search_wrap">-->
<!--            <select class="input_style01" name="time_kind" onchange="change_time_kind(this)">-->
<!--              <option value="0">전체</option>-->
<!--              <option value="24">최근 24시간</option>-->
<!--              <option value="3">최근 3일</option>-->
<!--              <option value="7">최근 7일</option>-->
<!--              &lt;!&ndash;<option value="31">최근 한달</option>&ndash;&gt;-->
<!--            </select>-->
<!--          </div>-->
          <div class="my_search_wrap">
            <table cellspacing="0" cellpadding="0" border="0" width="95%" style="margin-left: 4px;">
              <tbody><tr>
                <td width="49%"><input class="input_style01 hasDatepicker" readonly="" value="" placeholder="검색시작시간" id="date1" name="date1" type="date" v-model="payload.startDate"></td>
                <td style="width:80px;padding: 0px 20px 0px 20px;text-align:center">~</td>
                <td width="49%"><input class="input_style01 hasDatepicker" readonly="" value="" placeholder="검색종료시간" id="date2" name="date2" type="date" v-model="payload.endDate"></td>
              </tr>
              </tbody></table>
          </div>

        </form>
        <dl width="100%" style="">
          <br>
          <div class="snb_wrap" id="BC_TAB">
            <ul>
              <li><a @click="setResult(null)"><span id="TAB_ALL" :class="{'snb_on': !payload.result, 'snb': payload.result}">전체</span></a></li>
              <li><a @click="setResult('hit')"><span id="TAB_HIT" :class="{'snb_on': payload.result === 'hit', 'snb': payload.result !== 'hit'}">적중</span></a></li>
              <li><a @click="setResult('miss')"><span id="TAB_MISS" :class="{'snb_on': payload.result === 'miss', 'snb': payload.result !== 'miss'}">미적중</span></a></li>
              <li><a @click="setResult('wait')"><span id="TAB_ING" :class="{'snb_on': payload.result === 'wait' , 'snb': payload.result !== 'wait'}">진행중</span></a></li>
              <li><a @click="setResult('exception')"><span id="TAB_HITONE" :class="{'snb_on': payload.result === 'exception' , 'snb': payload.result !== 'exception'}">적중특례</span></a></li>
<!--              <li><a @click="setResult()"><span id="TAB_CANCEL" class="snb">취소</span></a></li>-->
            </ul>
          </div>
          <dt style="width:100%; padding:2px 0px 0px 0px;margin :0px 0px 0px 0px;">
            <div class="bet_gray" style="text-align: center;" v-if="!lists">
              <Loading></Loading>
            </div>
            <div class="bet_gray" v-if="isLoading && lists.length === 0" style="width:100%;padding :50px 0px 50px 5px;margin :0px 0px 0px 0px;text-align:center;">
              해당 기간내에 해당 조건의 배팅내역이 없습니다.
            </div>
            <template v-for="(row, index) in lists">
              <div
                  class="m_betting_div"
                  :key="'bettingTotal' + row[0].betting_bet_code + index"
              >
                <div
                    class="total_betting_div"
                    :class="{
                      'bg-y': row[0].betting_total_result === 'exception',
                      'bg-r': row[0].betting_total_result === 'miss',
                      'bg-g': row[0].betting_total_result === 'hit',
                      'bg-gr': row[0].betting_total_result === 'wait',
                    }"
                    @click="toggle(index)"
                >
                  <table border="0" width="100%">
                    <tr>
                      <td class="text-gray">
                        일시 :
                        <span>{{ row[0].betting_regdatetime | formatDate("YY-MM-DD HH:mm:ss") }}</span>
                        <span v-if="row[0].betting_total_result === 'hit' && row[0].betting_payed === 'n'" class="text-green">&nbsp;&nbsp;지급대기</span>
                      </td>
                      <td width="20%" rowspan="6">
                        <button type="button" v-if="row[0].betting_total_result !== 'wait' || row[0].betting_status !== '정상'">
                          <span @click="deleteBetting(row[0])">삭제</span>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td style="color: rgb(153, 153, 153)">
                        베팅아이디 :
                        <span
                        >{{ row[0].betting_betcode }} /
                  {{ row[0].betting_type }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        폴더
                        <span class="orange--text">{{ row.length }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        베팅금액
                        <span class="orange--text">{{
                            row[0].betting_bet_amount | makeComma
                          }}</span
                        >원
                      </td>
                    </tr>
                    <tr>
                      <td>
                        베당율
                        <span class="orange--text">{{ row[0].betting_total_odds }}</span>배
                      </td>
                    </tr>
                    <tr>
                      <td>
                        (예상)적중금액
                        <span class="orange--text">{{ row[0].betting_expected_prize | makeComma }}</span
                        >원
                      </td>
                    </tr>
                  </table>
                </div>
                <transition name="fade">
                  <div style="" v-if="opened.includes(index)">
                    <div
                        class="sub_betting_div"
                          :class="{
                            'border-y': row2.betting_result === 'exception',
                            'border-r': row2.betting_result === 'miss',
                            'border-g': row2.betting_result === 'hit',
                            'border-gr': row2.betting_result === 'wait',
                          }"
                        v-for="(row2, index2) in row"
                        :key="'bettingDetail' + index2 + row2.betting_folder"
                    >
                      <table
                          border="0"
                          width="100%"
                          style="color: white; font-size: 14px; padding-left: 10px"
                      >
                        <tr class="text-gray">
                          <td colspan="2" class="pt-2 text-gray">
                            {{
                              row2.betting_game_starttime | formatDate("YY-MM-DD HH:mm")
                            }}
                            |
                            {{
                              row2.betting_game_leagues_name_kr ||
                              row2.betting_game_leagues_name_en
                            }}
                          </td>
                        </tr>
                        <tr class="text-gray">
                          <td class="text-gray">게임아이디: {{ row2.betting_game }}</td>
                          <td
                              class="text-gray text-right pr-3"
                              style="text-align: right; line-height: 1.4"
                          >
                            {{
                              row2.betting_odds
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td style="color: white">
                            {{
                              row2.betting_game_home_name_kr ||
                              row2.betting_game_home_name_en
                            }}
                            <span class="grey--text text-gray"> vs </span>
                            {{
                              row2.betting_game_away_name_kr ||
                              row2.betting_game_away_name_en
                            }}
                          </td>
                          <td
                              class="text-right pr-3"
                              v-if="row2.betting_status.indexOf('취소') < 0"
                              style="text-align: right; line-height: 1.4;">
                            <span v-if="row2.betting_result == 'hit'" style="color: #44d344">{{ row2.betting_result | bettingStatus }}</span>
                            <span v-else style="color: white">{{ row2.betting_result | bettingStatus }}</span>
                          </td>
                          <td
                              class="text-right pr-3"
                              style="text-align: right; line-height: 1.4; color: white"
                              v-else
                          >
                            취소
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="pb-2" style="color: white">
                    <span style="color: deepskyblue">{{
                        row2.betting_game_markets_name_kr ||
                        row2.betting_game_markets_name_en
                      }}</span>
                            <span v-if="row2.betting_round"
                            >( {{ row2.betting_round }} )</span
                            >
                            / {{ row2 | betsName2 }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                        class="btnBox02"
                        style="margin-top: 10px; margin-bottom: 50px"
                        v-if="cancelStatus"
                    >
                      <template
                        v-if="bettingCancelLoading.indexOf(row[0].betting_betcode) < 0"
                      >
                        <a
                          v-if="cancelStatus(row)"
                          @click="cancelBetting(row[0].betting_betcode)"
                          class="btn05"
                          style="width: 100%"
                          >베팅 취소</a
                        >
                      </template>
                      <template v-else>
                        <Loading></Loading>
                      </template>
                    </div>
                  </div>
                </transition>
              </div>
            </template>
            <Pagination
                v-if="pagination.end"
                :pagination="pagination"
                :req-data="reqData"
                :payload="payload"
            ></Pagination>
          </dt>
        </dl>
      </div>
    </div>
    <!-- 인디케이터 -->
<!--    <div-->
<!--      class="m-game-wrap"-->
<!--      v-if="!lists"-->
<!--      style="min-height: 100px; text-align: center"-->
<!--    >-->
<!--      <div class="m-game-body">-->
<!--        <div class="m-game">-->
<!--          <div>-->
<!--            <Loading></Loading>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; 인디케이터 &ndash;&gt;-->
<!--    <div-->
<!--      class="m-game-wrap"-->
<!--      v-if="isLoading && lists.length === 0"-->
<!--      style="min-height: 100px; text-align: center"-->
<!--    >-->
<!--      <div class="m-game-body">-->
<!--        <div class="m-game">-->
<!--          <div class="team">-->
<!--            <div class="name" style="margin-top: 20px">-->
<!--              <span>베팅내역이 없습니다.</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import Pagination from "../components/Pagination03";
import Loading from "@/components/Loading";
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "BettingList",
  components: {
    Loading,
    Pagination,
  },
  data: function () {
    return {
      path: "betting/results",
      isLoading: false,
      loading: {
        isLoading: true,
        color: "orange",
        size: "80px",
      },
      payload: {
        result: null,
        startDate: null,
        endDate: null,
        page: 1,
        size: 20,
      },
      pagination: {},
      selectedBet: [],
      opened: [],
      bettingCancelLoading: [],
      deleteBets: [],
    };
  },
  mounted: function () {
    this.reqData(this.payload);
  },
  computed: {
    bettingCancel: function () {
      return this.$store.getters["CANCEL_OPTION"];
    },
    beforeStartCancel: function () {
      return this.$store.getters["BEFORE_START_CANCEL_OPTION"];
    },
    isMobile: function () {
      return this.$store.state.page.isMobile;
    },
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.bettingHistory
    }),
  },
  methods: {
    reqData: function (payload) {
      this.isLoading = false;
      this.$store.dispatch('BETTING_HISTORY', { payload })
          .then((data) => {
            const result = data.data.payload;
            console.log('리절트', result)
            this.isLoading = true
            this.pagination = result.pagination;
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    deleteBetting: function (bet) {
      if (confirm("삭제 하시겠습니까?")) {
        if (
          bet.betting_status === "정상" &&
          bet.betting_total_result === "wait"
        ) {
          return alert(
            "선택하신 베팅이 모두 결과 대기 중이므로 삭제하실 수 없습니다."
          );
        } else {
          const betCode = bet.betting_betcode;
          return this.$store
            .dispatch("DELETE_BET_RESULT", { payload: { betCode } })
            .then((data) => {
              const result = data.data;
              alert(result.msg);
              this.reqData(this.payload);
            });
        }
      }
    },
    deleteAllBetting: function () {
      if (confirm("삭제 하시겠습니까?")) {
        return this.$store
          .dispatch('DELETE_ALL_BET_RESULT')
          .then((data) => {
            const result = data.data;
            alert(result.msg);
            this.reqData(this.payload);
          });
      }
    },
    deleteSelectedBetting: function () {
      if (this.deleteBets.length > 0) {
        if (confirm(`${this.deleteBets.length}개를 삭제 하시겠습니까?`)) {
          const statusFilter = (bet) =>
            bet.betting_status !== "정상" ||
            bet.betting_total_result !== "wait";
          const getCode = (bet) => bet.code;
          const deleteList =
            this.deleteBets.filter(statusFilter).map(getCode) || [];
          if (deleteList.length > 0) {
            const data = {
              payload: {
                betCode: deleteList,
              },
            };
            return this.$store
              .dispatch('DELETE_BET_RESULT', data)
              .then((data) => {
                const result = data.data;
                alert(result.msg);
                this.reqData(this.payload);
                window.location.reload();
              });
          } else {
            alert(
              "선택하신 베팅이 모두 결과 대기 중이므로 삭제하실 수 없습니다."
            );
            this.reqData(this.payload);
            window.location.reload();
          }
        }
      } else {
        alert("선택된 베팅 내역이 없습니다.");
        this.reqData(this.payload);
        window.location.reload();
      }
    },
    cancelBetting: function (betCode) {
      if (confirm("취소 하시겠습니까?")) {
        this.toggleBettingCancel(betCode);
        this.loading.isLoading = true;

        return this.$store
          .dispatch('PROCESS_CANCEL_BETTING', { payload: { betCode } })
          .then((data) => {
            this.reqData(this.payload);
            const result = data.data;
            this.$store.dispatch('ME');
            this.loading.isLoading = false;
            location.reload();
            alert(result.msg);
          });
      }
    },
    cancelStatus: function (betting) {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      if (betting[0].betting_status !== "정상") return false;
      if (
        betting
          .filter((e) => e.betting_type !== "보너스")
          .find((e) => e.betting_result !== "wait")
      )
        return false;
      if (
        betting
          .filter((e) => e.betting_type !== "보너스")
          .find((e) => e.betting_type === "라이브")
      )
        return false;
      if (
        betting
          .filter((e) => e.betting_type !== "보너스")
          .find((e) => e.betting_type === "인플레이")
      )
        return false;
      const bettingTime = moment(betting[0].betting_regdatetime)
        .subtract(9, "hours")
        .add(this.bettingCancel.allowedTime, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      if (this.bettingCancel.active && now > bettingTime) return false;
      // 1. 가장 빠른 시간을 찾는다.
      let minGame = betting.find(
        (e) =>
          moment(e.betting_game_starttime)
            .subtract(this.beforeStartCancel.allowedTime, "seconds")
            .format("YYYY-MM-DD HH:mm:ss") < now
      );
      // 시작시간전 취소
      // console.log(minGame.betting_game_starttime);
      // console.log('시작전 취소', now, minGameStarttime, now > minGameStarttime)
      if (minGame) return false;
      // 베팅후 취소
      return true;
    },
    toggleBettingCancel: function (code) {
      const index = this.bettingCancelLoading.indexOf(code);
      if (index > -1) {
        this.bettingCancelLoading.splice(index, 1);
      } else {
        this.bettingCancelLoading.push(code);
      }
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    toggleCheckBox: function (row) {
      const code = row.betting_betcode;
      const index = this.selectedBet.indexOf(code);
      if (index > -1) {
        this.selectedBet.splice(index, 1);
        this.deleteBets.splice(index, 1);
      } else {
        this.selectedBet.push(code);
        this.deleteBets.push({
          code,
          betting_status: row.betting_status,
          betting_total_result: row.betting_total_result,
        });
      }
    },
    setResult(status) {
      this.payload.result = status;
      this.reqData(this.payload)
    }
  },
};
</script>

<style scoped>
@import "https://pro.fontawesome.com/releases/v5.13.0/css/all.css";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 10px 0 0 0;}
.my_search_wrap {width:100%; display:block; margin:0 0 10px 0}
.input_style01 {border:solid 1px #505455; background:none; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:100%; font-size: 15px;}


.snb_wrap {width:100%;  height:35px; line-height:35px; }
.snb_wrap ul li {float:left; margin:0 1px 0 0}
.snb_wrap ul li a{color:#ffffff}
.snb_on {padding:10px 8px 10px 8px; background:#da2c36; color:#ffffff; font-weight:bold}
.snb {padding:10px 8px 10px 8px; background:#242829; color:#ffffff; font-weight:bold}


/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#da2c36;
  font-weight: bold;
}
#contents_wrap {float:left; width:100%;}
.contents {width:1330px; margin:0px auto; min-height:600px; }

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}
</style>
