import axios from 'axios';
import store from '../index';

const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  LIVE_TOTAL_ODDS: function(state) {
    try {
      return Math.floor(state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1) * 100) / 100;
    } catch (error) {
      // 에러 발생 대비로 캐치블럭에 변경정 로직
      return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
    }
  },
}

const actions = {
  GET_LIVE_LIST({commit}, params) {
    return axios.post(`${API_URL}/game/live/new`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIVE_LIST', response.data?.payload.list)
        }
        return response
      })
  },

  ADD_CART_LIVE_ITEM({state, commit}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.LIVE_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.gameSeq === obj.gameSeq);
    if (equalCart){
      return commit('POP_ITEM_LIVE_CART_BY_FOLDER', obj); // 있을시 삭제
    }

    const cartItem = state.cart.find(item => item.gameSeq === obj.gameSeq && item.betSide === obj.betSide);
    if (!cartItem) {
      commit('PUSH_LIVE_ITEM_CART', obj);
    } else {
      commit('POP_ITEM_LIVE_CART', obj); // 있을시 삭제
    }
  },
  DELETE_CART_LIVE_ITEM({ commit }, obj){
    commit('POP_ITEM_LIVE_CART', obj); // 있을시 삭제
  },
  DELETE_CART_ITEM_GAME({commit}, gameId){
    commit('POP_ITEM_LIVE_CART_BY_GAME', gameId); // 있을시 삭제
  },
  ALL_CLEAR_LIVE_ITEM({ commit }){
    return commit('RESET_LIVE_CART')
  },
}

const mutations = {
  SET_LIVE_LIST(state, payload) {
    state.originList = payload.filter(g => g.isLimitBet === 'N')
  },
  SET_ESPORTS_LIST(state, payload) {
    state.originList = payload;
},

  PUSH_LIVE_ITEM_CART(state, obj){
    state.cart.push(obj)
  },
  POP_ITEM_LIVE_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.gameSeq === obj.gameSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_LIVE_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_LIVE_CART_BY_GAME(state, gameId){
    const index = state.cart.findIndex(i => i.gameId === gameId);
    if (index === -1) {
      state.cart = [...state.cart ]
      return;
    }
    return state.cart.splice(index, 1);
  },
  RESET_LIVE_CART(state){
    state.cart = [];
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}
