<template>
  <div class="bet-box" :class="{'disable_bet': gameStatus}">
    <div class="stit"></div>
    <div class="tab2">
      <div class="left">
        <button
            class="bg-blue"
            :class="{'active': checkCart(powerBallOdd)}"
            @click="addCart(powerBallOdd, game.folders.find(e => e.folders_detail.indexOf('파워볼/홀짝') >= 0), game)"
        >파워볼 홀</button>
        <button
            class="bg-red"
            :class="{'active': checkCart(powerBallEven)}"
            @click="addCart(powerBallEven, game.folders.find(e => e.folders_detail.indexOf('파워볼/홀짝') >= 0), game)"
        >파워볼 짝</button>
        <button
                :class="{'active': checkCart(powerBallUnder)}"
                @click="addCart(powerBallUnder, game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버' )>= 0), game)"
        >파워볼 0~4</button>
        <button
            :class="{'active': checkCart(powerBallOver)}"
            @click="addCart(powerBallOver, game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0), game)"
        >파워볼 5-9</button>
      </div>
      <div class="right">
        <button :class="{'active': checkCart(normalLarge)}"
                @click="addCart(normalLarge, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0), game)"
        >일반볼 대 (81 ~ 130)</button>
        <button
            :class="{'active': checkCart(normalMedium)}"
            @click="addCart(normalMedium, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0), game)"
        >일반볼 중 (65 ~ 80)</button>
        <button
            :class="{'active': checkCart(normalSmall)}"
            @click="addCart(normalSmall, game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대')>=0), game)"
        >일반볼 소 (15 ~ 64)</button>
      </div>
    </div>
    <div class="mine-box">
      <div class="mine">
        <dl>
          <dt>회차</dt>
          <dd><strong>{{game.game_round}}</strong></dd>
        </dl>
        <dl>
          <dt>보유머니</dt>
          <dd><strong>{{ user.members_cash | makeComma }}</strong></dd>
        </dl>
        <dl>
          <dt>배율</dt>
          <dd>{{ amount }} X {{ totalOdds }} = <span>{{ winnings }}</span></dd>
        </dl>
        <dl>
          <dt>베팅금액</dt>
          <dd><input type="text" class="inp1" v-model="amount"></dd>
        </dl>
      </div>
    </div>
    <div class="moneys-box">
      <div class="moneys">
        <a  @click="addAmount(10000)">1만원</a>
        <a  @click="addAmount(50000)">5만원</a>
        <a  @click="addAmount(100000)">10만원</a>
        <a  @click="addAmount(500000)">50만원</a>
        <a  @click="addAmount(1000000)">100만원</a>
        <a  @click="resetCart">정정</a>
      </div>
    </div>
    <div class="btns-box">
      <button @click="halfBet">HALF</button>
      <button @click="maxBet">MAX</button>
    </div>
    <div class="btn-box2">
      <button @click="processBet" style="background: #da2c36;">베팅하기</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";

export default {
  name: "MCartPowerBall",
  props: ['game', 'getGame', 'checkCart', 'gameType', 'reqData'],
  data: function(){
    return {
      // 베팅금액
      amount: 0,
      // 당첨금
      winnings: 0,
      // 당첨금 상한선
      limitWinnings: 0,
      // 현재 배당에서 최대 배팅금액,
      maxOddsAmount: 0,
      gameStatus: false,
      //현재시간
      interval: null,
      path: 'betting/mini/process',
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      cart: state => state.minigame.cart,
    }),
    ...mapGetters({
      totalOdds: 'MINI_TOTAL_ODDS',
      levelSetting: 'LEVEL_SETTING',
    }),
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.miniSetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.miniSetting.maxBetting);
    },
    powerBallOdd: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(e => e.bets_name === '홀')
    },
    powerBallEven: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/홀짝') >= 0).bets.find(e => e.bets_name === '짝')
    },
    powerBallUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(e => e.bets_name === '언더')
    },
    powerBallOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/언더오버') >= 0).bets.find(e => e.bets_name === '오버')
    },
    normalOdd: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(e => e.bets_name === '홀')
    },
    normalEven: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/홀짝') >= 0).bets.find(e => e.bets_name === '짝')
    },
    normalUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(e => e.bets_name === '언더')
    },
    normalOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/언더오버') >= 0).bets.find(e => e.bets_name === '오버')
    },
    normalSmall: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '소')
    },
    normalMedium: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '중')
    },
    normalLarge: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/소중대') >= 0).bets.find(e => e.bets_name === '대')
    },
    normalOddOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(e => e.bets_name === '홀+오버')
    },
    normalOddUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(e => e.bets_name === '홀+언더')
    },
    normalEvenOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(e => e.bets_name === '짝+오버')
    },
    normalEvenUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('일반볼/조합') >= 0).bets.find(e => e.bets_name === '짝+언더')
    },
    powerOddOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(e => e.bets_name === '홀+오버')
    },
    powerOddUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(e => e.bets_name === '홀+언더')
    },
    powerEvenOver: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(e => e.bets_name === '짝+오버')
    },
    powerEvenUnder: function(){
      if (!this.game) return null;
      return this.game.folders.find(e => e.folders_detail.indexOf('파워볼/조합') >= 0).bets.find(e => e.bets_name === '짝+언더')
    },
    endTime: function(){
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '파워볼').endTime
    },
  },
  mounted() {
    this.interval = setInterval(this.inter, 1000)
    this.clearCart
  },
  beforeDestroy() {
    this.clearCart()
    clearInterval(this.interval);
  },
  watch: {
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      this.maxOddsAmount = Math.floor(this.limitWinnings / val);
      if (this.maxOddsAmount < this.amount) {
        this.amount = this.maxOddsAmount
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
        }
        if (this.balance < Number(val)) {
          this.amount = this.balance;// return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
        }
        this.calWinnings();
      },
      immediate: true,
    },
  },
  methods: {
    addCart: function (bet, folder, game) {
      this.$store.dispatch('ADD_BET_MINI', {
        gameId: game.game_id,
        gameSeq: game.game_seq,
        betsSeq: bet.bets_seq,
        betsStatus: bet.bets_status,
        betsId: bet.bets_id,
        betsName: bet.bets_name,
        betsUpdated: false,
        betsPrevOdds: bet.bets_price,
        sportsId: bet.game_sport,
        marketsId: null,
        foldersSeq: folder.folders_seq,
        foldersType: folder.folders_type,
        odds: folder.folders_detail.indexOf('소중대') >= 0 && bet.bets_name === '중' ? 2.6 : folder.folders_detail.indexOf('소중대') >= 0 && bet.bets_name !== '중' ? 2.9 : 1.95,
        line: bet.bets_line,
        marketNameKr: folder.folders_detail,
        marketNameEn: folder.folders_detail,
        leagueNameKr: 'N파워볼',
        leagueNameEn: 'N파워볼',
        leagueNameIcon: null,
        sportNameKr: game.game_name,
        sportNameEn: game.game_name,
        sportNameIcon: null,
        homeNameKr: folder.folders_detail.indexOf('홀짝') >= 0 ? '홀' : folder.folders_detail.indexOf('언더오버') >= 0 ? '언더' : '소',
        homeNameEn: folder.folders_detail.indexOf('홀짝') >= 0 ? '홀' : folder.folders_detail.indexOf('언더오버') >= 0 ? '언더' : '소',
        drawNameKr: folder.folders_detail.indexOf('소중대') >= 0 ? '중' : null,
        awayNameKr: folder.folders_detail.indexOf('홀짝') >= 0 ? '짝' : folder.folders_detail.indexOf('언더오버') >= 0 ? '오버' : '대',
        awayNameEn: folder.folders_detail.indexOf('홀짝') >= 0 ? '짝' : folder.folders_detail.indexOf('언더오버') >= 0 ? '오버' : '대',
        startTime: game.game_starttime,
        odds1: folder.folders_detail.indexOf('소중대') >= 0 ? '2.9' : '1.95',
        odds2: folder.folders_detail.indexOf('소중대') >= 0 ? '2.6' : null,
        odds3: folder.folders_detail.indexOf('소중대') >= 0 ? '2.9' : '1.95',
        betType: '미니게임',
        round: this.game.game_round,
      })
    },

    addAmount: function(amt){
      this.amount += amt;
      console.log(this.amount);
    },
    resetCart: function (){
      this.amount = 0;
    },
    halfBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.balance < this.maxAmount / 2){
        this.amount = this.balance;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.balance  > this.maxAmount / 2){
        this.amount = Math.floor(this.maxAmount / 2)
      }
    },
    maxBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.balance < this.maxAmount){
        this.amount = this.balance;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.balance > this.maxAmount){
        this.amount = this.maxAmount;
      }
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.totalOdds);
    },
    //카트에서 item 삭제
    changeProcess: function(){
      this.process = !this.process;
    },
    processBet: function(){
      let data = {};
      if (this.cart.length === 0) return alert(`선택후 베팅을 진행해주세요.`);
      if (!this.isActiveFreeBet && this.amount < this.minAmount) return alert(`최소 베팅금은 ${this.minAmount}원 입니다.`);
      if (this.cart.find(e => e.startTime < moment().subtract(9, "hours").format('YYYY-MM-DD HH:mm:ss'))) return alert('이미 시작한 경기입니다.')
      this.changeProcess();
      data.bettings = this.cart;
      data.amount = this.amount;
      data.totalOdds = this.totalOdds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);
      this.$store.dispatch('SHOW_MODAL', 'bettingMiniProcess')
      setTimeout(this.reqData, 4000);

      this.amount = 0;
      this.$store.dispatch('CLEAR_MINI');
    },
    inter: function(){
      this.gameStatus = this.gameChangeStatus();
      // console.log(this.gameStatus);
      this.gameStarted()
    },
    gameChangeStatus: function(){
      if (this.game){
        const now = moment().unix()
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix()
        // console.log(startTime - now, this.endTime);
        if (this.endTime < startTime - now) {
          return false;
        } else {
          this.$store.dispatch('CLEAR_MINI');
          this.$store.dispatch('INVISIBLE_ALL_MODALS');
          return true
        }
      }
      return false;
    },
    gameStarted: function(){
      if (this.game){
        const now = moment().unix();
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix();
        if (startTime - now <= 1) {
          this.getGame();
          setTimeout(this.reqData, 1000);
        }
      } else {
        this.getGame()
      }
    },
    clearCart: function(){
      this.$store.dispatch('CLEAR_MINI');
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
