<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="wrap">
      <h2 class="subTitle1"><span>차곡차곡 쌓이는</span>출첵이벤트!</h2>
      <div class="inner_flex">
        <div data-v-339c7226="" class="game-side mt0" style="float: unset; min-width: 230px;">
          <div class="list">
           <a class="" @click="movePage('event/lotto')"><strong data-v-b405d1aa="">로또</strong></a>
            <a class="active"><strong data-v-b405d1aa="">출석부</strong></a>
            <a class="" @click="movePage('event/roulette')"><strong data-v-b405d1aa="">룰렛</strong></a>
          </div>
        </div>

        <div style="text-align: center; width:100%; min-height: 900px;" v-if="!isLoading">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <ul class="cal_list" v-else style="min-width: 970px;">
          <li v-for="(row, index) in takes" :key="'takes'+index" :class="{'finish': index < count }">
            <span class="day">{{ index + 1 }}</span>
            <img src="@/assets/img/etc/event_point_roulette.png" width="93" v-if="Number(row.roulette) > 0">
            <img src="@/assets/img/etc/p1000.png" width="93" v-else-if="Number(row.point) <= 1000">
            <img src="@/assets/img/etc/p1500.png" width="93" v-else-if="Number(row.point) === 1500">
            <img src="@/assets/img/etc/p2000.png" width="93" v-else-if="Number(row.point) === 2000">
            <img src="@/assets/img/etc/p4000.png" width="93" v-else-if="Number(row.point) === 4000">
            <img src="@/assets/img/etc/p30000.png" width="93" v-else-if="Number(row.point) >= 10000 && Number(row.point) < 50000 ">
            <img src="@/assets/img/etc/p50000.png" width="93" v-else-if="Number(row.point) === 50000">
  <!--            <img src="@/assets/img/etc/event_roulette.png" width="93" v-if="row.type.indexOf('룰렛') >= 0 && row.type.indexOf('포인트') < 0">-->
            <div class="ready_event">{{ row.point | makeComma }} 포인트 
              <span v-if="row.roulette && Number(row.roulette) > 0">+ 룰렛 {{ row.roulette }} 장</span>
              <span v-if="row.freebet && row.freebet > 0">+ 프리벳 {{ row.freebet | makeComma }}원</span>
            </div>
          </li>
        </ul>

        <div class="check_info_text">
          <h3>출석 체크 이벤트 참여하는 방법</h3>
          <p v-html="renderHtml(description)"></p>
          <template v-if="!processing">
            <button v-if="status" @click="process">출석 체크</button>
            <button v-else disabled >충전 완료 후 출석 체크해 주세요</button>
          </template>
          <template v-else>
            <div style="text-align: center; width:100%; min-height: 900px;">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
  <div v-else >
    <div class="header2">
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>출석부</h2>
    </div>
    <div class="tabs1">
     <a @click="$router.push('/event/lotto')">로또</a>
      <a @click="$router.push('/event/chulsuk')" class="active">출석부</a>
      <a @click="$router.push('/event/roulette')">룰렛</a>
    </div>
    <div class="inner_flex">
      <div style="text-align: center; width:100%;" v-if="!isLoading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
      <ul class="cal_list" v-else>
        <li v-for="(row, index) in takes" :key="'takes'+index" :class="{'finish': index < count}">
          <span class="day">{{ index + 1 }}</span>
          <img src="@/assets/img/etc/event_point_roulette.png" width="93" v-if="Number(row.roulette) > 0">
          <img src="@/assets/img/etc/p1000.png" width="93" v-else-if="Number(row.point) <= 1000">
          <img src="@/assets/img/etc/p1500.png" width="93" v-else-if="Number(row.point) === 1500">
          <img src="@/assets/img/etc/p2000.png" width="93" v-else-if="Number(row.point) === 2000">
          <img src="@/assets/img/etc/p4000.png" width="93" v-else-if="Number(row.point) === 4000">
          <img src="@/assets/img/etc/p30000.png" width="93" v-else-if="Number(row.point) >= 10000 && Number(row.point) < 50000 ">
          <img src="@/assets/img/etc/p50000.png" width="93" v-else-if="Number(row.point) === 50000">
<!--            <img src="@/assets/img/etc/event_roulette.png" width="93" v-if="row.type.indexOf('룰렛') >= 0 && row.type.indexOf('포인트') < 0">-->
          <div class="ready_event">{{ row.point | makeComma }} 포인트 
            <span v-if="row.roulette && Number(row.roulette) > 0">+ 룰렛 {{ row.roulette }} 장</span>
            <span v-if="row.freebet && row.freebet > 0">+ 프리벳 {{ row.freebet | makeComma }}원</span>
          </div>
        </li>
      </ul>
      <div class="check_info_text">

        <p v-html="renderHtml(description)"></p>
        <template v-if="!processing">
          <button v-if="status" @click="process">출석 체크</button>
          <button v-else disabled >충전 완료 후 출석 체크해 주세요</button>
        </template>
        <template v-else>
          <div style="text-align: center; width:100%; min-height: 900px;">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventChulsuk",
  data: function(){
    return {
      count: 0,
      isLoading: false,
      status: false,
      processing: false
    }
  },
  computed: {
    setting() {
      return this.$store.getters['CHULSUK_SETTING']
    },
    description: function(){
      return this.setting.description;
    },
    takes: function(){
      return this.setting.takes2;
    }
  },
  mounted() {
    this.reqData();
  },
  methods: {
    reqData: function(){
      this.isLoading = false;
      return this.$store.dispatch('CHECK_CHULSUK_STATUS')
          .then(data => {
            const result = data.data;
            if (result.success){
              this.count = result.payload.count
              this.status = result.payload.status
              this.isLoading = true;
            }
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    process: function(){
      if (this.processing) return alert('처리중 입니다.');
      this.processing = true;
      return this.$store.dispatch('PROCESS_CHULSUK', {payload: this.payload})
          .then(data => {
            const result = data.data;
            this.reqData();
            if (result.success){
              alert(result.msg);
              this.processing = false;
            }
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    movePage: function(url){
      this.$router.push(`/${url}`)
    }
  },
}
</script>

<style scoped>

</style>
